<template>
  <header id="header">
    <div class="header-area">
        <nav class="navbar navbar-expand-lg">
            <div class="container">
                <a class="navbar-brand" href="https://timerni.com">
                    <img src="https://timerni.com/assets/frontend/img/logo/f-logo.png" alt="logo">
                </a>
                <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" @click="mobile_menu = !mobile_menu" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <i class="fas fa-bars"></i>
                    <i class="fas fa-times"></i>
                </button>
                <div class="collapse navbar-collapse" id="navbarSupportedContent" :class="{'mobile-collapse':mobile_menu}">
                    <ul class="navbar-nav m-auto">
                        <li class="nav-item">
                            <a class="nav-link  active " aria-current="page" href="https://timerni.com">Home</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link " href="https://timerni.com/services">Services</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link " href="https://timerni.com/project">Projects</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link " href="https://timerni.com/contact">Contact Us</a>
                        </li>
                        <li class="nav-item pb-d-0">
                            <div class="dropdown">
                                <a href="javascript:void(0);" class="nav-link toggle-none dropdown-toggle" id="dropdownMenuButton1" @click="career_dropdown = !career_dropdown" data-bs-toggle="dropdown" aria-expanded="false">
                                    <span class="me-2">Career</span>
                                    <i class="fas fa-chevron-down"></i>
                                </a>
                                <ul class="dropdown-menu career" aria-labelledby="dropdownMenuButton1" v-if="career_dropdown">
                                    <li class="nav-items">
                                        <a class="nav-link dropdown-item" href="https://career.timerni.com">
                                            <span class="ms-2">Job</span>
                                        </a>
                                    </li>
                                    <li class="nav-items">
                                        <a class="nav-link dropdown-item" href="https://timerni.com/ambassador">
                                            <span class="ms-2">Ambassador</span>
                                        </a>
                                    </li>
                                    <li class="nav-items">
                                        <a class="nav-link dropdown-item" href="https://timerni.com/cv-clinic">
                                            <span class="ms-2">CV Clinic</span>
                                        </a>
                                    </li>
                                </ul>

                            </div>
                        </li>
                        
                    </ul>
                    <form class="d-flex align-items-center">
                        <div class="form-inline d-none d-lg-block">
                            <a href="javascript:void(0);" class="top-search">
                                <i class="fas fa-search"></i>
                            </a>
                        </div>
                                                    <div class="login">
                                <a href="https://timerni.com/user-login">
                                    Login
                                </a>
                            </div>
                                                
                    </form>
                </div>
            </div>
        </nav>
    </div>

</header>
</template>

<script>
// Utilities
import { mapMutations, mapState, mapActions } from "vuex";
// Components
export default {
  name: "CoreTopBar",
  computed: {},
  data: () => ({
    baseEnvLocal: baseEnv,
    mobile_menu:false,
    career_dropdown:false,
  }),
  async created() {
  },
  computed: {
    
  },
  methods: {
    toHome() {
      this.$router.push("/");
    },
    
  },
};
</script>

<style scoped>
  .left-logo{
    position: absolute;
    left: 5.47%;
    right: 69.22%;
    top: 6.94%;
    bottom: 64.44%;
  }
</style>
